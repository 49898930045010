import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 12 months',
}

const questions: Array<Question> = [
  {
    question: 'What is the difference between Russian Lips and Classic Lips?',
    answer:
      'The main difference is the side profile. Classic Lips protrude outwards and balance out a  ' +
      'strong nose or chin. Go easy on the volume, though - too much can lead to the dreaded ‘trout ' +
      'pout’. If someone has delicate features, they will suit Russian Lips, which look flat from the ' +
      'side. This technique lifts the cupids bow and adds height to a thin top lip. ',
  },
  {
    question: 'Does Russian Lip work differently?',
    answer:
      'We use the same Hyaluronic Acid filler but a different technique. Tiny droplets of filler are  ' +
      'injected vertically from the inner lips to the outer lip border. This technique lifts the lips to  ' +
      'avoid projection. Most of the injections are into the middle of the lips for a beautiful heart-' +
      'shaped look. In contrast, Classic Lips are injected horizontally from the lip border inwards.  ',
  },
  {
    question: 'Does Russian Lip hurt more than Classic Lip?',
    answer:
      'Most dermal fillers contain a local anaesthetic, and your experienced Ada Aesthetics ' +
      ' practitioner will ensure your treatment is as comfortable as possible. Russian Lips are less  ' +
      'painful than Classic Lips because we use fewer injections and only one in the sensitive lip ' +
      'border. However, there is more risk of swelling and bruising. Results should settle after two weeks.',
  },
]

const RussianLips: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Russian flat lips - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax
        titleName="Russian flat lips"
        customClass="page_title"
      />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                Do you have a thin top lip? Petite features? The Classic Lip
                might not be the right shape for your side profile; Russian Lips
                might suit you better. Take a tip from Russian women – they
                understand good lips.
              </p>
              <p>Just imagine the heart-shaped lips of a Russian doll.</p>
              <p>
                At Ada Aesthetics, we have an artistic eye and understand that
                different face shapes suit different lip shapes. We promise that
                your new lips will be in perfect proportion with the rest of
                your face. It’s time to feel more confident about your smile!
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default RussianLips

export const pageQuery = graphql`
  query RussianLips {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
